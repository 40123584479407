import { useCallback, useEffect, useRef, useState } from 'react';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { syspromptEditorConfig, validateSyspromptContent, ValidationResult } from '../utils/syspromptEditorUtils';

interface SyspromptTemplateCodeEditorProps {
  value: string;
  onChange: (value: string, isValid: boolean) => void;
  processText?: () => void;
}

export const SyspromptTemplateCodeEditor = ({ value, onChange, processText }: SyspromptTemplateCodeEditorProps) => {
  const editorRef = useRef<ReactCodeMirrorRef>(null);
  const [validation, setValidation] = useState<ValidationResult>(validateSyspromptContent(value));

  const handleChange = useCallback((value: string) => {
    const validationResult = validateSyspromptContent(value);
    setValidation(validationResult);
    onChange(value, validationResult.isValid);
  }, [onChange]);

  useEffect(() => {
    if (editorRef.current?.view?.dom) {
      const editorElement = editorRef.current.view.dom;
      editorElement.style.height = '100%';
      editorElement.style.width = '100%';
    }
  }, [value, editorRef.current]);

  useEffect(() => {
    if (!processText) {
      return;
    }

    // Initial run
    processText();
    // Set interval to run every 5 seconds
    const intervalId = setInterval(processText, 5000);

    return () => clearInterval(intervalId);
  }, [processText]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-2">
        <label className="block text-sm font-medium text-gray-700">
          Set Systemprompt Template
        </label>
        {!validation.isValid && (
            <span className="text-sm text-red-500">
            Missing tag{validation.missingTags.length > 1 ? 's' : ''}: {validation.missingTags.map(tag => `p==${tag}`).join(', ')}
            </span>
        )}
      </div>
      <div className={`flex-grow h-full w-full p-1 border rounded-md shadow-sm ${!validation.isValid ? 'border-red-500' : ''}`}>
        <CodeMirror
          ref={editorRef}
          value={value}
          onChange={handleChange}
          height="100%"
          width="100%"
          style={{ height: '100%' }}
          theme={syspromptEditorConfig.theme}
          extensions={syspromptEditorConfig.extensions}
        />
      </div>
    </div>
  );
};
