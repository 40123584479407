import { HighlightStyle, StreamLanguage, syntaxHighlighting } from '@codemirror/language';
import { EditorView } from '@codemirror/view';
import { Tag } from '@lezer/highlight';

const customTags = {
  level1: Tag.define(),
  level2: Tag.define(),
  level3: Tag.define(),
  level4: Tag.define(),
  level5: Tag.define(),
  level6: Tag.define(),
  content1: Tag.define(),
  content2: Tag.define(),
  content3: Tag.define(),
  content4: Tag.define(),
  content5: Tag.define(),
  content6: Tag.define()
};
// Define custom syntax highlighting rules
const customLanguage = StreamLanguage.define({
  startState: () => ({
    tagLevel: 0 // Tracks the current tag level (1-5) or 0 if not in tag
  }),

  tokenTable: customTags,

  token: (stream, state) => {

    // Check for opening or closing tags (p==N)
    if (stream.match(/p==([1-6])/)) {
      const level = stream.string[stream.pos - 1];

      if (state.tagLevel === 0) {
        state.tagLevel = parseInt(level);
      } else {
        if (parseInt(level) === state.tagLevel) {
          state.tagLevel = 0;
        }
      }
      return 'level' + level;  // Changed token format
    }

    // Handle text between tags
    if (state.tagLevel > 0) {
      stream.next();
      return 'content' + state.tagLevel;  // Changed token format
    }

    stream.next();
    return null;
  }
});


const customHighlightStyle = HighlightStyle.define([
  { tag: customTags.level1, color: '#e11d48', backgroundColor: '#fee2e2' },
  { tag: customTags.level2, color: '#2563eb', backgroundColor: '#dbeafe' },
  { tag: customTags.level3, color: '#059669', backgroundColor: '#dcfce7' },
  { tag: customTags.level4, color: '#7c3aed', backgroundColor: '#f3e8ff' },
  { tag: customTags.level5, color: '#ea580c', backgroundColor: '#ffedd5' },
  { tag: customTags.level6, color: '#0284c7', backgroundColor: '#e0f2fe' },
  { tag: customTags.content1, color: '#e11d48' },
  { tag: customTags.content2, color: '#2563eb' },
  { tag: customTags.content3, color: '#059669' },
  { tag: customTags.content4, color: '#7c3aed' },
  { tag: customTags.content5, color: '#ea580c' },
  { tag: customTags.content6, color: '#0284c7' }
]);

// Editor configuration object
export const syspromptEditorConfig = {
  theme: EditorView.theme({
    '&': {
      outline: 'none !important'
    },
  }),

  extensions: [
    EditorView.lineWrapping,
    customLanguage,
    syntaxHighlighting(customHighlightStyle)
  ]
};

// interface that defines how to replace each tag content
export interface TagContentReplacer {
  Tag1: string;
  Tag2: string;
  Tag3: string;
  Tag4: string;
  Tag5: string;
  Tag6: string;
}

// Tag replacer
export const replaceTagContent = (
  value: string,
  newValues: TagContentReplacer,
  onChange: (value: string) => void
) => {
  const replaceTag = (value: string, tag: string, content: string) => {
    const regex = new RegExp(`p==${tag}(.*?)p==${tag}`, 'sg');
    return value.replace(regex, `p==${tag}${content}p==${tag}`);
  }

  let newValue = value;
  newValue = replaceTag(newValue, '1', newValues.Tag1);
  newValue = replaceTag(newValue, '2', newValues.Tag2);
  newValue = replaceTag(newValue, '3', newValues.Tag3);
  newValue = replaceTag(newValue, '4', newValues.Tag4);
  newValue = replaceTag(newValue, '5', newValues.Tag5);
  newValue = replaceTag(newValue, '6', newValues.Tag6);

  if (newValue !== value) {
    onChange(newValue);
  }
};

export interface ValidationResult {
  isValid: boolean;
  missingTags: string[];
}

export const validateSyspromptContent = (content: string): ValidationResult => {
  const requiredTags = ['1', '2', '3', '4', '5', '6'];
  const missingTags = requiredTags.filter(tag => {
    const regex = new RegExp(`p==${tag}.*?p==${tag}`, 's');
    return !regex.test(content);
  });

  return {
    isValid: missingTags.length === 0,
    missingTags
  };
};
